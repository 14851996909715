<template>
  <div class="contacts">
    <h2 class="contacts__title">Sign up for updates on exhibitions, artists and events.</h2>

    <ValidationObserver class="contacts__container" v-slot="{ invalid }" tag="div" ref="observer">
      <div class="contacts__container_input-box">
        <ValidationProvider
          class="input"
          tag="div"
          :rules="{ required: true, email: true, min: 2, max: 255 }"
          v-slot="{ errors }"
        >
          <SubscriptionInput
            label="Email"
            placeholder="Enter your email"
            v-model="email"
            :validateError="errors[0]"
          ></SubscriptionInput>
        </ValidationProvider>
        <BaseButton
          class="button button--xs button--white"
          text="SUBMIT"
          :disabled="invalid || !isAgree"
          @click="submit"
        />
      </div>

      <div class="contacts__container_agree-box">
        <input type="checkbox" id="checkbox" v-model="isAgree" />
        <span class="text"
          >I agree to the
          <router-link class="link" to="/terms-and-conditions">Terms & Conditions</router-link>
          and
          <router-link class="link" to="/privacy-policy">Privacy Policy.</router-link></span
        >
      </div>
      <BaseButton
        class="button button--xs button--white mobile"
        text="SUBMIT"
        :disabled="invalid || !isAgree || submiting"
        @click="submit"
      />
    </ValidationObserver>
  </div>
</template>

<script>
import BaseButton from "@/components/buttons/BaseButton.vue";
import SubscriptionInput from "@/components/inputs/SubscriptionInput.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { BaseButton, SubscriptionInput, ValidationObserver, ValidationProvider },

  data() {
    return {
      isAgree: false,
      submiting: false,
      email: "",
    };
  },

  computed: {
    ...mapGetters("publicapi/contact", {
      serverError: "getServerError",
    }),
  },

  methods: {
    ...mapActions("publicapi/contact", ["apiSubscribe"]),

    async submit() {
      this.submiting = true;
      let success = await this.apiSubscribe({ email: this.email });
      if (success) {
        this.email = "";
        this.isAgree = false;
        this.$nextTick(() => {
          this.$refs.observer.reset();
        });
      }
      this.submiting = false;
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  color: $white;
  font-size: 1.8rem;
  line-height: 2.8rem;
  @media screen and (max-width: $xs) {
    font-size: 1.6rem;
    line-height: 2.5rem;
  }
}

.contacts {
  &__title {
    font-size: 4rem;
    line-height: 5rem;
    margin-bottom: 1rem;
    @media screen and (max-width: $xs) {
      display: inline-block;
      text-align: center;
      font-size: 3.5rem;
      line-height: 4.3rem;
      margin-bottom: 3rem;
    }
  }

  &__container {
    &_input-box {
      display: flex;
      align-items: flex-end;
      .input {
        width: 100%;
      }
      .button {
        height: 5.2rem;
        margin-left: 12px;
        @media screen and (max-width: $xs) {
          display: none;
        }
      }
    }

    &_agree-box {
      display: flex;
      align-items: center;
      margin-top: 20px;

      #checkbox {
        margin-right: 8px !important;
      }
      .text {
        @media screen and (max-width: $xs) {
          font-size: 1.4rem;
        }
        .link {
          text-underline-offset: 2px;
          @media screen and (max-width: $xs) {
            font-size: 1.4rem;
          }
        }
      }
    }
    .button.mobile {
      display: none;
      min-width: 142px;
      margin-top: 2.3rem;
      margin-left: calc(50% - 71px);
      @media screen and (max-width: $xs) {
        display: flex;
      }
    }
  }
}
</style>
