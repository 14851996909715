<template>
  <div class="contact-us">
    <h2 class="contact-us__title text-title">Contact Us</h2>
    <p class="contact-us__description text-description">
      To purchase artwork featured on this website or to obtain information about other available
      works, please contact browngrotta arts.
    </p>
    <ValidationObserver class="contact-us__form" v-slot="{ invalid }" tag="div" ref="observer">
      <ValidationProvider
        tag="div"
        class="contact-us__input"
        :rules="{ required: true, min: 2, max: 255 }"
        v-slot="{ errors }"
      >
        <SubscriptionInput
          class="form-control--inquire"
          placeholder="Your Name"
          v-model="form.username"
          :validateError="errors[0]"
        ></SubscriptionInput>
      </ValidationProvider>
      <ValidationProvider
        tag="div"
        class="contact-us__input"
        :rules="{ required: true, email: true, min: 2, max: 255 }"
        v-slot="{ errors }"
      >
        <SubscriptionInput
          class="form-control--inquire"
          placeholder="Email Address"
          v-model="form.email"
          :validateError="errors[0]"
        ></SubscriptionInput>
      </ValidationProvider>
      <ValidationProvider
        tag="div"
        class="contact-us__input"
        :rules="{ required: true, phone_number: true }"
        v-slot="{ errors }"
      >
        <SubscriptionInput
          class="form-control--inquire"
          placeholder="Phone Number"
          v-model="form.phone"
          :validateError="errors[0]"
        ></SubscriptionInput>
      </ValidationProvider>
      <ValidationProvider
        tag="div"
        class="contact-us__input"
        :rules="{ required: true, min: 2, max: 255 }"
        v-slot="{ errors }"
      >
        <BGATextarea
          class="form-control--inquire"
          placeholder="Message"
          v-model="form.message"
          :maxLength="255"
          :validateError="errors[0]"
        ></BGATextarea>
      </ValidationProvider>
      <BaseButton
        class="contact-us__button button--white"
        text="SUBMIT"
        :disabled="invalid"
        @click="submit"
      ></BaseButton>
    </ValidationObserver>

    <Contacts class="contact-us__contacts"></Contacts>
    <SignUpForUpdates class="contact-us__sign-up"></SignUpForUpdates>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
import { ValidationObserver, ValidationProvider } from "vee-validate";

import BaseButton from "@/components/buttons/BaseButton.vue";
import SubscriptionInput from "@/components/inputs/SubscriptionInput.vue";
import BGATextarea from "@/components/inputs/BGATextarea.vue";
import Contacts from "@/components/Contacts.vue";
import SignUpForUpdates from "@/components/SignUpForUpdates.vue";
import { nextTick } from "process";

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BaseButton,
    SubscriptionInput,
    BGATextarea,
    Contacts,
    SignUpForUpdates,
  },
  data() {
    return {
      form: {
        username: "",
        email: "",
        phone: "",
        message: "",
      },
    };
  },
  head: {
    title: function () {
      return {
        inner: "Contact Us browngrotta arts",
      };
    },
    meta: function () {
      return [
        {
          name: "description",
          itemprop: "description",
          content:
            "To purchase artwork featured on this website or to obtain information about other available works, please contact browngrotta arts.",
          id: "meta:description",
        },
        {
          itemprop: "name",
          content: "Contact Us browngrotta arts",
          id: "meta:name",
        },
        {
          itemprop: "image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "meta:image",
        },
        {
          property: "og:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "og:url",
        },
        {
          property: "og:type",
          content: "website",
          id: "og:type",
        },
        {
          property: "og:title",
          content: "Contact Us browngrotta arts",
          id: "og:title",
        },
        {
          property: "og:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "og:image",
        },
        {
          property: "og:image:alt",
          content: "Contact Us browngrotta arts",
          id: "og:image:alt",
        },
        {
          property: "og:description",
          content:
            "To purchase artwork featured on this website or to obtain information about other available works, please contact browngrotta arts.",
          id: "og:description",
        },
        {
          property: "og:site_name",
          content: process.env.VUE_APP_FRONTEND_URL,
          id: "og:site_name",
        },
        {
          property: "og:locale",
          content: "en_US",
          id: "og:locale",
        },
        {
          name: "twitter:card",
          content: "summary",
          id: "twitter:card",
        },
        {
          name: "twitter:site",
          content: "@BrowngrottaArts",
          id: "twitter:site",
        },
        {
          name: "twitter:creator",
          content: "@BrowngrottaArts",
          id: "twitter:creator",
        },
        {
          name: "twitter:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "twitter:url",
        },
        {
          name: "twitter:title",
          content: "Contact Us browngrotta arts",
          id: "twitter:title",
        },
        {
          name: "twitter:description",
          content:
            "To purchase artwork featured on this website or to obtain information about other available works, please contact browngrotta arts.",
          id: "twitter:description",
        },
        {
          name: "twitter:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "twitter:image",
        },
        {
          name: "twitter:image:alt",
          content: "Contact Us browngrotta arts",
          id: "twitter:image:alt",
        },
      ];
    },
  },

  created() {
    const breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Contact us",
        to: "/contact",
        clickable: false,
      },
    ];
    this.setBreadcrumbs(breadcrumbs);
  },

  computed: {
    ...mapGetters("publicapi/contact", {
      serverError: "getServerError",
    }),
  },

  methods: {
    ...mapActions("publicapi/contact", ["apiSendMessage"]),
    ...mapMutations(["setBreadcrumbs"]),

    async submit() {
      let success = await this.apiSendMessage(this.form);
      if (success) {
        this.form.username = "";
        this.form.email = "";
        this.form.phone = "";
        this.form.message = "";
        nextTick(() => {
          this.$refs.observer.reset();
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-us {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 16rem 41.5rem 23rem;
  @media screen and (max-width: $md) {
    padding: 16rem 10rem 23rem;
  }
  @media screen and (max-width: $xs) {
    padding: 24rem 25px 13rem;
  }
  &__title {
    font-size: 4rem;
    margin-bottom: 3rem;
    text-align: center;
  }
  &__description {
    //max-width: 70rem;
    max-width: 85rem;
    margin: 0rem auto 7.5rem;
    text-align: center;
  }
  &__form {
    //max-width: 39rem;
    max-width: 60rem;
    width: 100%;
    margin: 0 auto;
  }
  &__input {
    margin-bottom: 4.5rem;
  }
  &__button {
    min-width: 100%;
  }
  &__contacts {
    margin-top: 12rem;
    max-width: 60rem;
  }
  &__sign-up {
    margin: 15rem auto 0;
    max-width: 60rem;
  }
}
</style>
