<template>
  <div class="form-control" :class="{ 'form-control--invalid': validateError }">
    <div class="form-control__textarea-wrapper">
      <label class="form-control__label">
        {{ label }}
        <span class="form-control__label_required" v-if="required">*</span>
      </label>
      <textarea
        class="form-control__textarea"
        :class="{ 'form-control__textarea--count': maxLength }"
        ref="textarea"
        required
        :placeholder="placeholder"
        :value="value"
        @input="
          changeHeight();
          $emit('input', $event.target.value);
        "
      />
      <span v-if="maxLength" class="form-control__counter"
        >{{ value.length || 0 }}/{{ maxLength }}</span
      >
    </div>
    <p class="form-control__error">{{ validateError }}</p>
  </div>
</template>
<script>
export default {
  props: {
    label: { type: String },
    placeholder: { type: String },
    value: { type: String },
    required: { type: Boolean, default: false },
    validateError: { type: String, default: "" },
    maxLength: { type: [String, Number], required: false },
  },
  data() {
    return {
      showText: true,
    };
  },
  mounted() {
    this.changeHeight();
  },
  methods: {
    changeHeight() {
      let currenTextarea = this.$refs.textarea;
      currenTextarea.height = "auto";
      currenTextarea.style.height = currenTextarea.scrollHeight + "px";
    },
  },
};
</script>
<style lang="scss" scoped>
.form-control {
  width: 100%;
  position: relative;
  &__icon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
  }
  &__label {
    @include type($ff: $fontFrutiger, $fs: 1.8rem, $fw: 400, $lh: 1.2, $ls: -0.27px);
    color: $white;
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
    }
    &_required {
      font-size: 18px;
      font-weight: 600;
      color: $error;
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      }
    }
  }
  &__textarea {
    height: auto;
    min-height: 5.2rem;
    padding: 2rem 2rem 0;
    border: 0.5px solid $white;
    border-radius: 4px;
    @include type($ff: $fontFrutiger, $fs: 1.8rem, $fw: 400, $lh: 1.2);
    color: $white;
    overflow: hidden;
    margin-top: 7px;
    &:-webkit-autofill {
      background-color: rgba(255, 255, 255, 1) !important;
      background-image: none !important;
      -webkit-box-shadow: 0 0 0px 10em white inset !important;
      -webkit-text-fill-color: $black !important;
    }
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
    }
    &-wrapper {
      position: relative;
    }
    &::placeholder {
      color: rgba($color: $white, $alpha: 0.7);
    }
    &:-ms-input-placeholder {
      color: rgba($color: $white, $alpha: 0.7);
    }
    &::-ms-input-placeholder {
      color: rgba($color: $white, $alpha: 0.7);
    }
  }
  &__error {
    position: absolute;
    @include type($fs: 12px, $fw: 500);
    color: $error;
    padding-top: 3px;
    width: max-content;
  }
  &__counter {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translateY(100%);
    font-family: $fontFrutiger;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 1.3;
  }
  &--invalid {
    .form-control__textarea {
      border-color: $error;
    }
  }
  &--inquire {
    .form-control__textarea {
      height: 4rem;
      min-height: 6.3rem;
      padding: 1rem 0 1rem;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid $grey;
      margin: 0;
      background: none;
      &:focus {
        border-bottom: 1px solid $white;
        &::placeholder {
          color: $white;
        }
        &:-ms-input-placeholder {
          color: $white;
        }
        &::-ms-input-placeholder {
          color: $white;
        }
      }
    }
  }
  &--simple {
    .form-control__label {
      position: relative;
      display: block;
      width: auto;
      top: initial;
      left: initial;
      transform: initial;
      background: transparent;
      color: $white;
      padding: 0;
      margin-bottom: 13px;
      font-family: $fontFrutiger;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.3;
      height: 18px;
      &_required {
        font-family: $fontFrutiger;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.3;
        color: $white;
      }
    }
    .form-control__textarea {
      min-height: 19.5rem;
      font-family: $fontFrutiger;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.3;
      color: $white;
      border-radius: 4px;
      background-color: rgba($color: $white, $alpha: 0.1);
      padding: 15px;
      &--count {
        padding-right: 10rem;
      }
      &::-moz-placeholder {
        color: $white;
        opacity: 1;
      }
      &::-ms-input-placeholder {
        color: $white;
        opacity: 1;
      }
      &::-webkit-input-placeholder {
        color: $white;
        opacity: 1;
      }
    }
  }
}
</style>
