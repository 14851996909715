<template>
  <div class="notification-marker">
    <IconExclamationMark class="notification-marker__svg" />
  </div>
</template>

<script>
import IconExclamationMark from "@/components/icons/IconExclamationMark.vue";

export default {
  components: { IconExclamationMark },

  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
* {
  color: $white;
}

.notification-marker {
  position: relative;
  height: 18px;
  width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  border: 1px solid $white;
  background-color: rgba(255, 255, 255, 0.2);
}
</style>
