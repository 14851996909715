<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" :viewBox="viewBox">
    <g>
      <path
        :fill="color"
        fill-rule="evenodd"
        d="M1.21038038 7.33333333c-.48920248 0-.89926927.38-.89926927.83333334C.31111111 8.62.7211779 9 1.21038038 9c.4712171 0 .89926927-.38.8776868-.81333333.02158247-.47666667-.38488724-.85333334-.8776868-.85333334z"
      />
      <path
        :fill="color"
        fill-rule="evenodd"
        d="M.91722772.02851701C.35168317.16589191 0 .60225926 0 1.1315567c.02851485.31919464.05227723.6424297.08079208.96162434C.16158416 3.309353.24237624 4.50128231.32316832 5.71745427c.02851485.41212472.4039604.71111716.88871287.71111716s.8649505-.31919463.88871287-.73535979c0-.25050718 0-.48081217.02851485-.73535979.05227723-.7798046.10930693-1.55960922.16158416-2.33941383.02851485-.5050548.08079208-1.0101096.10930693-1.5151644 0-.18181972-.02851485-.34343726-.10930693-.5050548-.24237624-.4525291-.8079208-.68283408-1.37346535-.5697018z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    viewBox: { type: String, default: "0 0 2.4 9" },
    width: { type: String, default: "2.4" },
    height: { type: String, default: "9" },
    color: { type: String, default: "#fff" },
  },
};
</script>
